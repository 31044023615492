.App {
  text-align: center;
}

.App-logo {
  height: 26vmin;
  pointer-events: none;
}

.App-header {
  background-color: #FBE6DC;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

.Description {
  max-width: 50rem;
  padding: 0 10px;
}

.Download-wrapper {
  display: flex;
  justify-content: center;
}

.Android-img {
  height: 5rem;
}

.iOS-img {
  padding: 0.9rem;
  height: 3.35rem;
}

@media only screen and (max-width: 440px) {
  .Download-wrapper {
    flex-direction: column;
  }

  .Android-img {
    height: unset;
    width: 205px;
  }
  
  .iOS-img {
    padding: 0.9rem;
    height: unset;
    width: 179px;
  }
}